
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of users</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-lg-12 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="sendInvitation()">
                <button class="dropdown-item" type="button">
                  Send Invitation
                </button>
              </li>
              <li @click.prevent="exportSelected()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <router-link class="btn btn-primary" to="/users/create">
            <i class="ri ri-add-fill align-bottom"></i> <span class="d-inline"> Add User </span></router-link>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select @change="fetchItems()" v-model="defaultRole" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by role --</option>
              <option :value="null"> All </option>
              <option v-for="(role, tIndex) in roleOptions"
                      :value="role.value" :key="tIndex" > {{role.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                v-slot:[`head(${column.key})`] >
                  <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                  d-flex align-items-center">
                    {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                  </div>
              </template>
              <template v-slot:head(check)>
                <div style="width:3.5rem;" class="form-check text-center">
                  <label class="form-check-label me-2" for="tableSRadioHead">
                    <input @change="selectAllRows($event)" type="checkbox"
                      class="form-check-input" id="tableSRadioHead" />
                  </label>
                </div>
              </template>
              <template v-slot:cell(check)="data">
                <div style="width:3.5rem;" class="form-check text-center me-auto">
                  <input v-model="selectedItems" type="checkbox" :value="data.item.id" class="form-check-input me-2"
                    :id="`tableSRadio${data.item.id}`" @click="handleCheckboxClick($event, data.item)" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                </div>
              </template>
              <template v-slot:cell(image)="data">
                <div class="cursor-pointer">
                  <img @click="showProfileImg(data.item.image)" class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(email)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.email }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)" class=""></span>
                </div>
              </template>
              <template v-slot:cell(is_verified)="data">
                <span class="text-capitalize badge" :class="data.item.is_verified ? 'bg-primary' : 'bg-danger'"> 
                    {{ data.item.is_verified ? '✓' :'X' }} </span>
              </template>
              <template v-slot:cell(group)="data">
                <div v-if="data.item.group" style="min-width:10rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.group.name }}</span>
                </div>
              </template>
              <template v-slot:cell(residential_address)="data">
                <div style="min-width:20rem;" v-if="data.item.residential_address" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.residential_address.street_address }}</span> <br>
                  <span class="text-dark font-sm" v-if="data.item.residential_address.lga">{{ data.item.residential_address.lga.name }}</span>,
                  <span class="text-dark font-sm" v-if="data.item.residential_address.state">{{ data.item.residential_address.state.name }}</span>
                </div>
              </template>
              <template v-slot:cell(polling_address)="data">
                <div style="min-width:20rem;" v-if="data.item.polling_address" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm" v-if="data.item.polling_address.unit">{{ data.item.polling_address.unit.name }}</span> <br>
                  <span class="text-dark font-sm" v-if="data.item.polling_address.ward">{{ data.item.polling_address.ward.name }}</span>,
                  <span class="text-dark font-sm" v-if="data.item.polling_address.lga">{{ data.item.polling_address.lga.name }}</span>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <div @click="navigateTo(data.item.id)" style="width:6rem;" class="cursor-pointer">
                  {{$filters.date(data.item.created_at,1) }} 
                </div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="dropdown dropstart no-arrow">
                  <button class="dropdown-toggle btn btn-sm btn-light" type="button"
                    role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li v-if="data.item.id != activeUser.id">
                      <button @click.prevent="deleteItem(data.item.id)" class="dropdown-item"
                        type="button"> Delete </button>
                    </li>
                  </ul>
                </div>
              </template>

            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal hide-footer v-model="popupModalInvitation" title="Invitation form">
        <meeting-invitation-form v-if="popupModalInvitation" 
          @closeMe="initializeAll()" :selected="selectedItems"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';
import MeetingInvitationForm from '@/components/forms/MeetingInvitationForm.vue';
import { commonSortingMixin } from '@/mixins/commonSortingMixin';
import { commonPaginationMixin } from '@/mixins/commonPaginationMixin';
import { commonSelectRowMixin } from '@/mixins/commonSelectRowMixin';


export default {
  name: "user-list",
  components:{
    IsLoading,
    MeetingInvitationForm,
  },
  mixins: [
    commonSortingMixin,
    commonPaginationMixin,
    commonSelectRowMixin
  ],
  data() {
    return {
      isLoading: true,
      defaultRole: null,
      searchQuery: "",
      popupModalInvitation: false,
      roleOptions:[
        { value: "admin", label: "Admin"},
        { value: "staff", label: "Staff"},
        { value: "grouphead", label: "Group Head"},
        { value: "member", label: "Member"},
      ],
      columns: [
        {
          key: "check",
        },
        {
          key: "image",
          label: "Image",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "mobile",
          label: "Phone",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "is_verified",
          label: "Verified",
          sortable: true
        },
        {
          key: "group",
          label: "Group",
        },
        {
          key: "residential_address",
          label: "Residential Address",
        },
        {
          key: "polling_address",
          label: "Polling Unit",
        },
        {
          key: "created_at",
          label: "Created at",
        },
        "action"
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "asc"
      },
    }
  },
  watch: {
    "$route.query" : "refreshQuery",
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.userList.pageData
    },
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    refreshQuery(){
      if(this.$route.query.role){
        this.defaultRole = this.$route.query.role
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.defaultRole){ payload.role = this.defaultRole }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("userList/fetchUsers", payload)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.initializeAll();
          }
        })
    },
    deleteItem(itemId){
      this.confirmDelete({
        text: "You want to delete a user",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/users/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit('userList/DELETE_USER', itemId)
            }
          })
        }
      });
    },
    sendInvitation(){
      if(!this.selectedItems.length){
        this.alertError("You have not selected any item"); return;
      }
      this.popupModalInvitation = true
    },
    exportSelected() {
      if(!this.selectedItems.length){
        this.alertError("Select the users you'd like to export."); return;
      }
      const json = this.pageData.data
                    .filter(cd => this.selectedItems.includes(cd.id))
                    .map(item => {
                      return {
                        sn: item.id,
                        first_name: item.first_name,
                        middle_name: item.middle_name,
                        last_name: item.last_name,
                        email: item.email,
                        mobile: item.mobile,
                        status: item.status,
                        image: item.image ? this.absoluteUrl(item.image) : '',
                        isVerified: item.is_verified ? "YES" : "NO",
                        role: item.role.name,
                        registeredOn:  this.$filters.date(item.created_at, 1),
                        residentialAddress: item.residential_address?.street_address,
                        residentialLga: item.residential_address?.lga?.name,
                        homeLga: item.polling_address?.lga?.name,
                        homeWard: item.polling_address?.ward?.name,
                        homeUnit: item.polling_address?.unit?.name,
                        groupName: item.group?.name,
                        groupCode: item.group?.friendly_id
                      }
                    })
        const csvString = this.jsonToCSV(json)
        this.downloadCSV(csvString, "users.csv")
    },
    initializeAll(){
      this.popupModalInvitation = false

      this.selectedItems = [];
      this.lastCheckedIndex = null
    }
  },
  created() {
    this.refreshQuery()
    if(!this.defaultRole){this.fetchItems()}
  }
}

</script>

